
import { computed, defineComponent, onMounted, ref } from "vue"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";
import { Field, Form } from "vee-validate";
import * as Validations from "yup";
import CashModule from "@/store/modules/sales copy/modules/cash";
import { getModule } from "vuex-module-decorators";
import Select from "@/components/forms/Select.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {VMoney} from 'v-money'

export default defineComponent({
    components:{
        Field,
        Form,
        Select
    },
    setup() {
        const money = ref(({//en algun momento se usara multilenguaje y aqui pueden aplicar los cambios
            decimal: '.',
            thousands: ',',
            prefix: '$ ',
            suffix: '',
            precision: 2,
            masked: false
        }))
        const moduleCash = getModule(CashModule);

        moduleCash.GET_INFO_CASH();
        moduleCash.GET_CASHREGISTER_COMBO();

        //Schema
        const validationSchema = Validations.object().shape({
            cashRegisterId:Validations.string().nullable().default('').required().label("cashRegisterId"),
            initialBalance: Validations.string()
                            // .default(0)
                            // .min(1, 'Ingrese una cantidad minima de 1')
                            // .typeError('Ingrese una cantidad valida')
                            .label("initialBalance")
        });

        //metodos
        const submitOpemCash = (data: any) => {
            if(infoCashUser.value.hasOpendCashRegister){
                 Swal.fire({
                    text: "No se puede inicializar la caja, usted cuenta con una caja abierta.",
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: "Aceptar",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    console.log("ok");
                });
            }else{
                const numero = parseFloat(data.initialBalance.replace(/[^\d.]/g, ''));

                let actionModule: Promise<boolean>;

                actionModule = moduleCash.OPEN_CASH({
                    cashierId: infoCashUser.value.id,
                    cashRegisterId: data.cashRegisterId,
                    // supervisorId: '08daa7fe-5f84-4dbb-8844-5002c7331fb9',
                    initialBalance: numero
                });
                
                actionModule.then((isCorrect) => {
                    // if(isCorrect){
                        moduleCash.GET_INFO_CASH();
                    // }
                });
            }
        }

        const infoCashUser = computed(() => moduleCash.getUserInfoCash);
        const lstCash = computed(() => moduleCash.getCashRegisters);

        onMounted(() => {
                setCurrentPageBreadcrumbs(translate('BREADCRUMB.OPENCASH'), [translate('BREADCRUMB.MODULES')]);
        });
        return {
            validationSchema
            , infoCashUser
            , lstCash
            , money

            , submitOpemCash
        }
    }, 
    directives:{
        money: VMoney
    }
})
